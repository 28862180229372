import React from 'react'
import { Box } from '@walltowall/calico'

import { ModalCloseButton } from './ModalCloseButton'
import { Heading } from './Heading'
import { Text } from './Text'
import { Button } from './Button'
import { ButtonLink } from './ButtonLink'

import { useModal } from '../hooks/useModal'
import { useFadedDiamondBackground } from '../hooks/useFadedDiamondBackground'

import * as styleRefs from './SpeedbumpModal.treat'

type SpeedbumpModalProps = {
  href: string
}

export const SpeedbumpModal = ({ href }: SpeedbumpModalProps) => {
  const { closeModal } = useModal()
  const backgroundClassName = useFadedDiamondBackground('beige100', 'top')

  return (
    <Box
      styles={{
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: [8, 12],
        paddingBottom: [8, 12],
        backgroundColor: 'beige100',
        position: 'relative',
      }}
      className={backgroundClassName}
    >
      <ModalCloseButton
        onClick={closeModal}
        styles={{ position: 'absolute', top: 0, right: 0 }}
      />

      <Heading
        level={3}
        variant="sansC"
        styles={{
          color: 'beige40',
          fontWeight: 'light',
          marginBottom: 4,
        }}
      >
        Attention
      </Heading>
      <Heading
        level={4}
        variant="sansCapsB"
        styles={{ color: 'fuschia30', marginBottom: 8 }}
      >
        You are leaving hawaiinational.bank
      </Heading>
      <Text
        styles={{ color: 'brown20', marginBottom: 8 }}
        className={styleRefs.text}
      >
        By clicking "OK" you acknowledge you are leaving our website. While we
        select companies we do business with because of their high level of
        quality, our online Privacy Policy does not apply to third-party sites;
        therefore, we encourage you to read the security/privacy information for
        each site you visit.
      </Text>

      <Box styles={{ display: 'flex', justifyContent: 'end' }}>
        <Button variant="red" styles={{ marginRight: 3 }} onClick={closeModal}>
          Cancel
        </Button>
        <ButtonLink
          variant="red"
          href={href}
          linkProps={{ skipExternalCheck: true }}
          onClick={closeModal}
        >
          Ok
        </ButtonLink>
      </Box>
    </Box>
  )
}
