import * as React from 'react'
import { Box, BoxProps, useBoxStyles } from '@walltowall/calico'

import { SVG } from './SVG'

import { ReactComponent as AssetIconChevronRightLargeSVG } from '../assets/icon-chevron-right-large.svg'
import { ReactComponent as AssetIconFacebookSquareGradientSVG } from '../assets/icon-facebook-square-gradient.svg'
import { ReactComponent as AssetIconInstagramSquareGradientSVG } from '../assets/icon-instagram-square-gradient.svg'
import { ReactComponent as AssetIconLinkedinSquareGradientSVG } from '../assets/icon-linkedin-square-gradient.svg'
import { ReactComponent as AssetIconTwitterSquareGradientSVG } from '../assets/icon-twitter-square-gradient.svg'
import { ReactComponent as AssetIconFacebookSquareSVG } from '../assets/icon-facebook-square.svg'
import { ReactComponent as AssetIconInstagramSquareSVG } from '../assets/icon-instagram-square.svg'
import { ReactComponent as AssetIconLinkedinSquareSVG } from '../assets/icon-linkedin-square.svg'
import { ReactComponent as AssetIconTwitterSquareSVG } from '../assets/icon-twitter-square.svg'
import { ReactComponent as AssetIconSearchSVG } from '../assets/icon-search.svg'
import { ReactComponent as AssetIconMarkerSVG } from '../assets/icon-marker.svg'
import { ReactComponent as AssetIconChevronDownSVG } from '../assets/icon-chevron-down.svg'
import { ReactComponent as AssetIconExclaimSVG } from '../assets/icon-exclaim.svg'
import { ReactComponent as AssetIconChevronRightSVG } from '../assets/icon-chevron-right.svg'
import { ReactComponent as AssetIconChevronLeftSVG } from '../assets/icon-chevron-left.svg'
import { ReactComponent as AssetIconCloseXSVG } from '../assets/icon-close-x.svg'

const icons = {
  chevronRightLarge: { svg: AssetIconChevronRightLargeSVG, x: 10.45, y: 34.61 },
  chevronDown: { svg: AssetIconChevronDownSVG, x: 12, y: 8 },
  chevronRight: { svg: AssetIconChevronRightSVG, x: 11, y: 17 },
  chevronLeft: { svg: AssetIconChevronLeftSVG, x: 11, y: 18 },
  search: { svg: AssetIconSearchSVG, x: 1, y: 1 },
  marker: { svg: AssetIconMarkerSVG, x: 18, y: 22 },
  exclaim: { svg: AssetIconExclaimSVG, x: 1, y: 1 },
  facebookSquare: {
    svg: AssetIconFacebookSquareSVG,
    x: 1,
    y: 1,
  },
  instagramSquare: {
    svg: AssetIconInstagramSquareSVG,
    x: 1,
    y: 1,
  },
  linkedinSquare: {
    svg: AssetIconLinkedinSquareSVG,
    x: 1,
    y: 1,
  },
  twitterSquare: {
    svg: AssetIconTwitterSquareSVG,
    x: 1,
    y: 1,
  },
  facebookSquareGradient: {
    svg: AssetIconFacebookSquareGradientSVG,
    x: 1,
    y: 1,
  },
  instagramSquareGradient: {
    svg: AssetIconInstagramSquareGradientSVG,
    x: 41,
    y: 40,
  },
  linkedinSquareGradient: {
    svg: AssetIconLinkedinSquareGradientSVG,
    x: 1,
    y: 1,
  },
  twitterSquareGradient: {
    svg: AssetIconTwitterSquareGradientSVG,
    x: 41,
    y: 40,
  },
  close: {
    svg: AssetIconCloseXSVG,
    x: 1,
    y: 1,
  },
}

export type IconProps = {
  name: keyof typeof icons
} & BoxProps

export const Icon = ({ name, ...props }: IconProps) => {
  const icon = icons[name]

  const svgClassName = useBoxStyles({ display: 'block' })

  return (
    <Box {...props}>
      <SVG svg={icon.svg} x={icon.x} y={icon.y} className={svgClassName} />
    </Box>
  )
}
