import React from 'react'
import { Box, BoxProps } from '@walltowall/calico'

import { Icon } from './Icon'

export const ModalCloseButton = ({ onClick, ...props }: BoxProps) => {
  return (
    <Box
      component="button"
      onClick={onClick}
      {...props}
      styles={{
        color: 'beige40',
        padding: 5,
        transitionProperty: 'color',
        transitionDuration: 'normal',
        transitionTimingFunction: 'easeOut',
        ...props.styles,
      }}
      hoverStyles={{ color: 'brown20', ...props.hoverStyles }}
      focusStyles={{ color: 'brown20', ...props.focusStyles }}
    >
      <Icon name="close" styles={{ width: '1.25rem' }} />
    </Box>
  )
}
