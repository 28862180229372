import React from 'react'
import { AspectRatio, AspectRatioProps } from '@walltowall/siamese'
import { useBoxStyles } from '@walltowall/calico'

type SVGProps<E extends React.ElementType> = {
  svg: React.ComponentType<any>
} & AspectRatioProps<E>

export const SVG = <E extends React.ElementType>({
  svg: SvgComp,
  x,
  y,
  ...props
}: SVGProps<E>) => {
  const svgClassName = useBoxStyles({ width: 'full', height: 'full' })

  return (
    <AspectRatio as="div" x={x} y={y} {...props}>
      <SvgComp className={svgClassName} />
    </AspectRatio>
  )
}
