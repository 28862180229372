import React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import ConditionalWrap from 'conditional-wrap'
import clsx from 'clsx'

import { Text } from './Text'

import * as styleRefs from './Button.treat'

const variants = {
  red: {
    backgroundColor: undefined,
    color: 'white',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 3,
    paddingBottom: 3,
    textVariant: 'sansCond-16-17',
    variant: 'red',
    size: 'base',
  },
  redSmall: {
    backgroundColor: undefined,
    color: 'white',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2.5,
    paddingBottom: 2.5,
    textVariant: 'sansCond-14',
    variant: 'red',
    size: 'small',
  },
  white: {
    backgroundColor: 'white',
    color: 'fuschia30',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 3,
    paddingBottom: 3,
    textVariant: 'sansCond-16-17',
    variant: 'white',
    size: 'base',
  },
  whiteSmall: {
    backgroundColor: 'white',
    color: 'fuschia30',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2.5,
    paddingBottom: 2.5,
    textVariant: 'sansCond-14',
    variant: 'white',
    size: 'small',
  },
} as const

export type ButtonProps = {
  component?: 'button' | 'div'
  variant: keyof typeof variants
  children?: React.ReactNode
  withTextWrapper?: boolean
} & BoxProps

export const Button = ({
  component = 'button',
  variant: variantName,
  children,
  withTextWrapper = true,
  className,
  ...props
}: ButtonProps) => {
  const styles = useStyles(styleRefs)

  const variant = variants[variantName]

  const classNames = clsx(
    className,
    styles.base,
    variant.size !== undefined && styles.sizes[variant.size],
    variant.variant !== undefined && styles.variants[variant.variant],
  )

  return (
    <Box
      component={component}
      className={classNames}
      {...props}
      styles={{
        backgroundColor: variant.backgroundColor,
        color: variant.color,
        paddingLeft: variant.paddingLeft,
        paddingRight: variant.paddingRight,
        paddingTop: variant.paddingTop,
        paddingBottom: variant.paddingBottom,
        overflow: 'hidden',
        transitionDuration: 'normal',
        ...props.styles,
      }}
    >
      <ConditionalWrap
        condition={withTextWrapper}
        wrap={(children) => (
          <Text
            component="span"
            variant={variant.textVariant}
            styles={{
              display: 'block',
              fontWeight: 'semibold',
              textAlign: 'center',
              letterSpacing: 's',
              textTransform: 'uppercase',
            }}
          >
            {children}
          </Text>
        )}
      >
        <>{children}</>
      </ConditionalWrap>
    </Box>
  )
}
