import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import { AspectRatio } from '@walltowall/siamese'
import HTMLRenderer, { HTMLRendererProps } from 'react-html-renderer'
import clsx from 'clsx'

import { useUtilStyles } from '../hooks/useUtilStyles'

import { Heading } from './Heading'
import { Anchor } from './Anchor'
import { Text } from './Text'

import * as styleRefs from './HTMLContent.treat'

const baseHeadingStyles = {
  marginTop: [8, 9, 10],
  marginBottom: [7, 8],
} as const

const baseTextStyles = {
  marginBottom: [6, 7, 8],
} as const

const labelStyles: Record<string, BoxProps['styles']> = {
  small: {
    fontSize: '0.85em',
  },
  large: {
    fontSize: ['2.5em', '3em'],
    fontWeight: 'light',
  },
  red: {
    color: 'fuschia30',
  },
  superscript: {
    fontSize: '0.85em',
    position: 'relative',
    top: '-0.5em',
  },
}

const components: HTMLRendererProps['components'] = {
  h1: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Heading
        variant="sansA"
        level={3}
        {...props}
        className={clsx(
          styles.headingTarget,
          firstLastNoMargin,
          props.className,
        )}
        styles={{ ...baseHeadingStyles, ...props.styles }}
      />
    )
  },
  h2: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Heading
        variant="sansC"
        level={4}
        {...props}
        className={clsx(
          styles.headingTarget,
          firstLastNoMargin,
          props.className,
        )}
        styles={{ ...baseHeadingStyles, ...props.styles }}
      />
    )
  },
  h3: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Heading
        variant="sansE"
        level={5}
        {...props}
        className={clsx(
          styles.headingTarget,
          firstLastNoMargin,
          props.className,
        )}
        styles={{ ...baseHeadingStyles, ...props.styles }}
      />
    )
  },
  h4: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Heading
        variant="serifCapsA"
        level={6}
        {...props}
        className={clsx(
          styles.headingTarget,
          firstLastNoMargin,
          props.className,
        )}
        styles={{
          ...baseHeadingStyles,
          marginBottom: baseTextStyles.marginBottom,
          ...props.styles,
        }}
      />
    )
  },
  h5: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Heading
        variant="sansCapsB"
        level={6}
        {...props}
        className={clsx(
          styles.headingTarget,
          firstLastNoMargin,
          props.className,
        )}
        styles={{
          ...baseHeadingStyles,
          marginBottom: baseTextStyles.marginBottom,
          ...props.styles,
        }}
      />
    )
  },
  h6: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Heading
        variant="sansCapsC"
        level={6}
        {...props}
        className={clsx(
          styles.headingTarget,
          firstLastNoMargin,
          props.className,
        )}
        styles={{
          ...baseHeadingStyles,
          marginBottom: baseTextStyles.marginBottom,
          ...props.styles,
        }}
      />
    )
  },
  p: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Text
        {...props}
        className={clsx(lastNoMargin, props.className)}
        styles={{ ...baseTextStyles, ...props.styles }}
      />
    )
  },
  ul: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Box
        component="ul"
        {...props}
        className={clsx(lastNoMargin, props.className)}
        styles={{
          ...baseTextStyles,
          paddingLeft: 7,
          listStyle: 'disc',
          ...props.styles,
        }}
      />
    )
  },
  ol: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Box
        component="ol"
        {...props}
        className={clsx(props.className, lastNoMargin)}
        styles={{
          ...baseTextStyles,
          paddingLeft: 7,
          listStyle: 'decimal',
          ...props.styles,
        }}
      />
    )
  },
  li: ({ children, className, ...props }) => {
    const { lastNoMargin, firstNegativeMargin } = useUtilStyles()

    return (
      <Box
        component="li"
        {...props}
        className={clsx(lastNoMargin, firstNegativeMargin, className)}
        styles={{
          display: 'listItem',
          alignItems: 'start',
          marginBottom: 2,
          fontSize: '1rem',
          lineHeight: 'body',
          ...props.styles,
        }}
      >
        {children}
      </Box>
    )
  },
  a: ({ href, ...props }) => <Anchor href={href!} {...props} />,
  strong: (props) => (
    <Box
      component="strong"
      {...props}
      styles={{ fontWeight: 'semibold', ...props.styles }}
    />
  ),
  span: ({ class: className, ...props }: { class?: string }) => (
    <Box
      component="span"
      data-test="test"
      {...props}
      styles={className ? labelStyles[className] : undefined}
    />
  ),
  // Embed support (e.g. videos)
  div: (props) => {
    if (props['data-oembed-type'] === 'video')
      return (
        <AspectRatio x={16} y={9}>
          <Box
            {...props}
            className={styleRefs.videoEmbedTarget}
            styles={{ width: 'full', height: 'full' }}
          >
            {props.children}
          </Box>
        </AspectRatio>
      )

    return <Box {...props} />
  },
}

export type HTMLContentProps = {
  html?: HTMLRendererProps['html']
  componentOverrides?: HTMLRendererProps['componentOverrides']
} & BoxProps

export const HTMLContent = ({
  html,
  componentOverrides,
  ...props
}: HTMLContentProps) => (
  <Box {...props}>
    <HTMLRenderer
      html={html}
      components={components}
      componentOverrides={componentOverrides}
    />
  </Box>
)
