import React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import * as styleRefs from './Heading.treat'

const levelComponentMap = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
} as const

const variantExtraStyles: Record<
  keyof typeof styleRefs.variants,
  BoxProps['styles']
> = {
  sansA: {
    fontFamily: 'sans',
    fontWeight: 'light',
  },
  sansB: {
    fontFamily: 'sans',
    fontWeight: 'light',
  },
  sansC: {
    fontFamily: 'sans',
    fontWeight: 'normal',
  },
  sansD: {
    fontFamily: 'sans',
    fontWeight: 'normal',
  },
  sansE: {
    fontFamily: 'sans',
    fontWeight: 'normal',
  },
  sansF: {
    fontFamily: 'sans',
    fontWeight: 'bold',
  },
  sansCapsA: {
    fontFamily: 'sans',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'l',
  },
  sansCapsB: {
    fontFamily: 'sans',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'm',
  },
  sansCapsC: {
    fontFamily: 'sans',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
    letterSpacing: 'm',
  },
  sansCapsD: {
    fontFamily: 'sans',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'm',
  },
  serifCapsA: {
    fontFamily: 'serif',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'm',
  },
}

type HeadingProps = {
  children?: React.ReactNode
  level: 1 | 2 | 3 | 4 | 5 | 6
  variant: keyof typeof styleRefs.variants
} & BoxProps

export const Heading = ({
  children,
  level,
  variant,
  className,
  ...props
}: HeadingProps) => {
  const styles = useStyles(styleRefs)

  const component = levelComponentMap[level]

  const variantClassName = clsx(styles.variants[variant], className)

  const extraStyles = variantExtraStyles[variant]

  return (
    <Box
      component={component}
      className={variantClassName}
      {...props}
      styles={{
        ...extraStyles,
        ...props.styles,
      }}
    >
      {children}
    </Box>
  )
}

Heading.variants = Object.keys(styleRefs.variants)
