import React, { useState } from 'react'
import { Box, BoxProps } from '@walltowall/calico'

import { Heading } from './Heading'
import { ModalCloseButton } from './ModalCloseButton'
import { Text } from './Text'
import { ButtonLink } from './ButtonLink'

import { useFadedDiamondBackground } from '../hooks/useFadedDiamondBackground'
import { useModal } from '../hooks/useModal'
import { HTMLContent } from './HTMLContent'

import * as styleRefs from './ReportLostCard.treat'
import { graphql, useStaticQuery } from 'gatsby'
import { UseLostCardPopupQuery } from 'graphqlTypes'

type CardOptionProps = BoxProps & {
  isActive?: boolean
}

const useLostCardPopup = () => {
  const queryData = useStaticQuery<UseLostCardPopupQuery>(graphql`
    query UseLostCardPopup {
      prismicLostCardPopup {
        data {
          credit_links {
            credit_link_footnote
            credit_link_text
            credit_link {
              url
            }
            credit_link_heading {
              text
            }
          }
          subheading {
            text
          }
          heading {
            text
          }
          caps_heading {
            text
          }
          copy {
            html
          }
          debit_links {
            debit_link {
              url
            }
            debit_link_footnote
            debit_link_heading {
              text
            }
            debit_link_text
          }
        }
      }
    }
  `)
  const data = queryData.prismicLostCardPopup?.data

  return {
    heading: data?.heading?.text,
    subheading: data?.subheading?.text,
    capsHeading: data?.caps_heading?.text,
    copy: data?.copy?.html,
    debitLinks: data?.debit_links?.map((debit) => {
      return {
        heading: debit?.debit_link_heading?.text,
        text: debit?.debit_link_text,
        link: debit?.debit_link?.url,
        footnote: debit?.debit_link_footnote,
      }
    }),
    creditLinks: data?.credit_links?.map((credit) => {
      return {
        heading: credit?.credit_link_heading?.text,
        text: credit?.credit_link_text,
        link: credit?.credit_link?.url,
        footnote: credit?.credit_link_footnote,
      }
    }),
  }
}

const CardOption = ({
  children,
  isActive = false,
  onClick,
  ...props
}: CardOptionProps) => {
  return (
    <Box
      component="button"
      onClick={onClick}
      className={styleRefs.options}
      {...props}
      styles={{
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: isActive ? 'beige80' : 'white',
        ...props.styles,
      }}
    >
      <Heading
        level={5}
        variant="sansCapsB"
        component="div"
        className={styleRefs.optionsText}
      >
        {children}
      </Heading>
    </Box>
  )
}

type CardType = 'credit' | 'debit'

export const ReportLostCardModal = () => {
  const [activeCardType, setActiveCardType] = useState<CardType>('debit')
  const backgroundClassName = useFadedDiamondBackground('beige100', 'top')
  const { closeModal } = useModal()
  const data = useLostCardPopup()

  const showDebit = () => setActiveCardType('debit')
  const showCredit = () => setActiveCardType('credit')

  const isShowingDebit = activeCardType === 'debit'
  const activeLinks = isShowingDebit ? data.debitLinks : data.creditLinks
  console.log(data)
  return (
    <Box
      styles={{
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: [8, 12],
        paddingBottom: [8, 12],
        backgroundColor: 'beige100',
        position: 'relative',
      }}
      className={backgroundClassName}
    >
      <ModalCloseButton
        onClick={closeModal}
        styles={{ position: 'absolute', top: 0, right: 0 }}
      />
      <Box styles={{ marginBottom: 8 }}>
        <Heading
          level={3}
          variant="sansC"
          styles={{
            color: 'beige40',
            fontWeight: 'light',
            marginBottom: 5,
          }}
        >
          {data.heading}
        </Heading>
        <Text
          variant="sans-19-22"
          styles={{ color: 'brown20', marginBottom: 6 }}
        >
          {data.subheading}
        </Text>
        <Heading
          level={4}
          variant="sansCapsB"
          styles={{ color: 'fuschia30' }}
          style={{ whiteSpace: 'pre-line' }}
        >
          {data.capsHeading}
        </Heading>
      </Box>

      <Box
        styles={{
          display: 'flex',
          flexDirection: ['column', 'row'],
        }}
      >
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: [null, '6/12'],
            marginBottom: [8, 0],
          }}
        >
          <Box
            styles={{
              display: 'flex',
              marginBottom: 6,
              backgroundColor: 'white',
              padding: 1,
              color: 'fuschia30',
            }}
            className={styleRefs.options}
          >
            <CardOption isActive={isShowingDebit} onClick={showDebit}>
              Debit
            </CardOption>
            <CardOption
              isActive={activeCardType === 'credit'}
              onClick={showCredit}
            >
              Credit
            </CardOption>
          </Box>

          {activeLinks?.map(({ heading, link, text, footnote }, index) => {
            const hasFootnote = Boolean(footnote)
            const isLastLink = index === data.debitLinks!.length - 1
            return (
              <>
                {heading && (
                  <Heading
                    level={5}
                    variant="sansF"
                    styles={{ marginBottom: 3 }}
                  >
                    {heading}
                  </Heading>
                )}
                {link && text && (
                  <ButtonLink
                    variant="red"
                    styles={{ marginBottom: hasFootnote ? 2 : 6 }}
                    href={link}
                    linkProps={{ skipExternalCheck: true }}
                  >
                    {text}
                  </ButtonLink>
                )}
                {hasFootnote && (
                  <Text
                    styles={{
                      color: 'brown20',
                      marginBottom: isLastLink ? 0 : 6,
                    }}
                  >
                    {footnote}
                  </Text>
                )}
              </>
            )
          })}
        </Box>

        <Box
          styles={{
            width: '1px',
            backgroundColor: 'beige40',
            opacity: 25,
            marginLeft: 8,
            marginRight: 8,
            display: ['none', 'block'],
          }}
        />
        {data.copy && (
          <HTMLContent
            styles={{
              color: 'brown20',
              paddingTop: 3,
              paddingBottom: 3,
              maxWidth: '50ch',
              alignSelf: [null, 'center'],
              width: [null, '6/12'],
            }}
            html={data.copy}
            componentOverrides={{
              p: (Comp) => (props) => (
                <Comp
                  styles={{ marginBottom: 6 }}
                  variant="sans-16"
                  {...props}
                />
              ),
            }}
          />
        )}
      </Box>
    </Box>
  )
}
