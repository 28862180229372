import React, { useState } from 'react'
import clsx from 'clsx'
import { useStyles } from 'react-treat'
import { useBoxStyles } from '@walltowall/calico'
import { DialogContent, DialogOverlay } from '@reach/dialog'

import { RoutingNumberModal } from '../components/RoutingNumberModal'
import { ReportLostCardModal } from '../components/ReportLostCardModal'
import { SpeedbumpModal } from '../components/SpeedbumpModal'

import * as styleRefs from './useModal.treat'

const modals = {
  LOST_CARD: {
    render: () => <ReportLostCardModal />,
    label: 'Lost card information',
  },
  ROUTING_NUMBER: {
    render: () => <RoutingNumberModal />,
    label: 'Routing number',
  },
  SPEEDBUMP: {
    render: (href?: string) => <SpeedbumpModal href={href!} />,
    label: 'Leaving HawaiiNationalBank.com',
  },
} as const

const ModalContext = React.createContext({
  openModal: (_type: keyof typeof modals, _href?: string) => {},
  closeModal: () => {},
})

export const useModal = () => React.useContext(ModalContext)

type ModalProviderProps = {
  children: React.ReactNode
}

type ModalState = {
  type?: keyof typeof modals
  href?: string
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const styles = useStyles(styleRefs)

  const [state, setState] = useState<ModalState>({})

  const openModal = (modalType: ModalState['type'], href?: string) =>
    setState({ type: modalType, href })
  const closeModal = () => setState((p) => ({ ...p, type: undefined }))

  const modal = state.type ? modals[state.type] : undefined

  const overlayStyles = useBoxStyles({
    zIndex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })

  const contentStyles = useBoxStyles({
    width: 'auto',
    margin: 0,
    padding: 0,
    color: 'brown20',
    fontFamily: 'sans',
    overflow: 'hidden',
    borderRadius: '0.7rem',
  })

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <DialogOverlay
        isOpen={Boolean(modal)}
        onDismiss={closeModal}
        className={clsx(overlayStyles, styles.overlay)}
        dangerouslyBypassScrollLock={true}
      >
        {modal && (
          <DialogContent aria-label={modal.label} className={contentStyles}>
            {modal.render(state.href)}
          </DialogContent>
        )}
      </DialogOverlay>
    </ModalContext.Provider>
  )
}
