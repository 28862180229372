import React from 'react'
import { useBoxStyles, usePseudoBoxStyles } from '@walltowall/calico'
import clsx from 'clsx'

import { Link, LinkProps } from './Link'

const focusStyles = {
  color: 'maroon10',
  textDecoration: 'underline',
} as const

export type AnchorProps = LinkProps

export const Anchor = ({ className, ...props }: AnchorProps) => {
  const classNames = clsx(
    useBoxStyles({
      color: 'fuschia30',
      transitionProperty: 'color',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles(focusStyles, 'hover'),
    usePseudoBoxStyles(focusStyles, 'focus'),
    className,
  )

  return <Link {...props} className={classNames} />
}
