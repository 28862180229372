import React from 'react'
import { Box } from '@walltowall/calico'

import { Heading } from './Heading'
import { Text } from './Text'
import { Picture } from './Picture'
import { ModalCloseButton } from './ModalCloseButton'

import { useSiteSettings } from '../hooks/useSiteSettings'
import { useModal } from '../hooks/useModal'

import assetRoutingNumberPng from '../assets/media-routing-number.png'
import assetRoutingNumberWebP from '../assets/media-routing-number.webp'
import { useFadedDiamondBackground } from '../hooks/useFadedDiamondBackground'

export const RoutingNumberModal = () => {
  const siteSettings = useSiteSettings()
  const { closeModal } = useModal()
  const backgroundClassName = useFadedDiamondBackground('beige100', 'top')

  return (
    <Box
      styles={{
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: [10, 10, 14],
        paddingBottom: [10, 10, 14],
        backgroundColor: 'beige100',
        position: 'relative',
      }}
      className={backgroundClassName}
    >
      <ModalCloseButton
        onClick={closeModal}
        styles={{ position: 'absolute', top: 0, right: 0 }}
      />

      <Box
        styles={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Text
          variant="sans-19-22"
          styles={{ color: 'beige40', marginBottom: [3, 6] }}
        >
          Routing Number
        </Text>
        <Heading
          level={3}
          variant="sansC"
          styles={{
            color: 'fuschia30',
            fontWeight: 'semibold',
            marginBottom: [8, 12],
          }}
        >
          {siteSettings.routingNumber}
        </Heading>
        <Picture
          baseSrc={assetRoutingNumberPng}
          baseType="png"
          webpSrc={assetRoutingNumberWebP}
          styles={{ width: '41rem' }}
        />
      </Box>
    </Box>
  )
}
