import * as React from 'react'

import { Link, LinkProps } from './Link'
import { Button, ButtonProps } from './Button'

type ButtonLinkProps = {
  href: LinkProps['href']
  linkProps?: Omit<LinkProps, 'href'>
} & ButtonProps

export const ButtonLink = ({ href, linkProps, ...props }: ButtonLinkProps) => {
  return (
    <Link href={href} {...linkProps}>
      <Button component="div" {...props} />
    </Link>
  )
}
