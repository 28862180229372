import React from 'react'
import clsx from 'clsx'
import { BoxProps, useBoxStyles, usePseudoBoxStyles } from '@walltowall/calico'

type PictureProps = Pick<
  BoxProps,
  'styles' | 'hoverStyles' | 'focusStyles' | 'className' | 'style'
> & {
  baseSrc: string
  webpSrc: string
  baseType: 'jpg' | 'png'
  loading?: 'lazy' | 'eager'
  alt?: string
}

export const Picture = ({
  baseSrc,
  webpSrc,
  baseType,
  loading = 'lazy',
  styles,
  focusStyles,
  hoverStyles,
  className,
  ...props
}: PictureProps) => {
  const resolvedStyles = clsx(
    useBoxStyles(styles),
    usePseudoBoxStyles(hoverStyles, 'hover'),
    usePseudoBoxStyles(focusStyles, 'focus'),
    className,
  )

  return (
    <picture>
      <source srcSet={webpSrc} type="image/webp" />
      <source srcSet={baseSrc} type={`image/${baseType}`} />
      <img
        className={resolvedStyles}
        src={baseSrc}
        loading={loading}
        {...props}
      />
    </picture>
  )
}
