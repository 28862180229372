import * as React from 'react'

const DebugContext = React.createContext([false, () => {}] as [
  boolean,
  () => void,
])

export const DebugProvider = ({ children }: { children?: React.ReactNode }) => {
  const reducer = React.useReducer((state) => !state, false)

  return (
    <DebugContext.Provider value={reducer}>{children}</DebugContext.Provider>
  )
}

export const useDebug = () => {
  const [state] = React.useContext(DebugContext)
  return state
}

export const useDebugController = () => React.useContext(DebugContext)
