import { useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { boolean } from 'boolean'
import { getRichText } from '@walltowall/helpers'

import { PrismicSiteSettingsQuery } from '../graphqlTypes'

const YEAR = new Date().getFullYear().toString()

export const useSiteSettings = () => {
  const queryData = useStaticQuery<PrismicSiteSettingsQuery>(graphql`
    query PrismicSiteSettings {
      prismicSettings {
        data {
          site_name {
            text
          }
          site_description {
            text
          }
          site_copyright {
            text
          }
          facebook_handle
          twitter_handle
          instagram_handle
          linkedin_handle
          routing_number
          ebanking_link {
            url
          }
          ebanking_sign_in_endpoint {
            url
          }
          show_ebanking_downtime
          ebanking_downtime_text {
            text
            html
          }
          catch_links_whitelist {
            host
          }
        }
      }
    }
  `)

  const settings = queryData.prismicSettings?.data

  return useMemo(
    () => ({
      siteName: settings?.site_name?.text,
      siteDescription: settings?.site_description?.text,
      siteCopyright: settings?.site_copyright?.text?.replace?.(/\$YEAR/g, YEAR),
      facebookHandle: settings?.facebook_handle,
      twitterHandle: settings?.twitter_handle,
      instagramHandle: settings?.instagram_handle,
      linkedinHandle: settings?.linkedin_handle,
      routingNumber: settings?.routing_number,
      ebankingUrl: settings?.ebanking_link?.url,
      ebankingSignInEndpoint: settings?.ebanking_sign_in_endpoint?.url,
      // TODO: Replace `boolean` with a proper boolean field in Prismic.
      showEbankingDowntime: boolean(settings?.show_ebanking_downtime),
      ebankingDowntimeTextHTML: getRichText(settings?.ebanking_downtime_text),
      catchLinksWhitelist: settings?.catch_links_whitelist?.map?.(
        (item) => item?.host,
      ),
    }),
    [settings],
  )
}
